<template>
  <div>
    <span>{{ $t("SWITCH_LANGUAGE") }}:</span>
    <a href="#" @click="showlanguage">
      {{getSelectedValue}}
      <svg height="20" width="20">
        <g transform="translate(0, 0) scale(1)">
          <path
            d="M9.99985814,13.00284 C9.43785814,13.00284 8.87785814,12.79084 8.43985814,12.36684 L4.22885814,8.28884 C3.93085814,8.00084 3.92285814,7.52584 4.21085814,7.22784 C4.49985814,6.93084 4.97485814,6.92384 5.27085814,7.21084 L9.48285814,11.28984 C9.77385814,11.56984 10.2258581,11.56984 10.5168581,11.28984 L14.7288581,7.21084 C15.0268581,6.92284 15.5018581,6.93084 15.7888581,7.22784 C16.0768581,7.52584 16.0688581,8.00084 15.7708581,8.28884 L11.5598581,12.36684 C11.1228581,12.79084 10.5618581,13.00284 9.99985814,13.00284"
            fill="rgba(52, 52, 52, 1)"
            style="transition: fill 0.2s ease 0s;"
          />
        </g>
      </svg>
    </a>

    <ou-panel :title="$t('SWITCH_LANGUAGE')" class="countryChooser" v-model='showLanguages'>

     <b-list-group flush>
        <b-list-group-item class="p-2"
          href="#"
          v-for="(value, key) in locales"
          :key="key"
          @click="setLocale(key)"
        >
         <b-row>
            <b-col cols="3" class="text-right no-right-gutter">
              <img class="flagicon" :src="getFlag(key)" />
            </b-col>
            <b-col cols="9" class="text-left no-left-gutter">{{value}}</b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

    </ou-panel>


  </div>
</template>
<script>
export default {
  data() {
    return {
      showLanguages: false,
      locale: this.$i18n.locale,
      locales: this.$store.state.lang.locales
    };
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.dispatch("lang/setLocale", locale);
      this.showLanguages = false;
    },
    getFlag(locale) {
      return "./img/flags/" + locale + ".png";
    },
    showlanguage() {
      this.showLanguages = true;
    }
  },
  computed: {
    getSelected() {
      return this.$i18n.locale;
    },
    getSelectedValue() {
      return this.locales[this.$i18n.locale];
    },
    currentFlag() {
      return "/img/flags/" + this.$i18n.locale + ".png";
    }
  }
};
</script>
